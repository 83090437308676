import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import NoSsr from '@/components/NoSsr/NoSsr';
import Cookies from 'js-cookie';
import { useAndroidBannersStore } from '@/store/useBannerStore/useAndroidBannerStore';
import { useMobileDetect } from '@/hooks/useMobileDetect';
import * as S from './CustomAppBanner.styles';

const timeOnAppStoreOpen = 4000;

export const CustomAppBanner = () => {
  const [isAndroidBannerVisible, setIsAndroidBannerVisible] =
    useAndroidBannersStore(state => [
      state.isAndroidBannerVisible,
      state.setIsAndroidBannerVisible,
    ]);
  const { t } = useTranslation();
  const { isAndroid, isIos } = useMobileDetect();
  const hostnameModified =
    typeof window !== 'undefined' &&
    `.${window.location.hostname?.replace(/^ru./, '')}`;

  const [onClickTime, setOnClickTime] = useState(false);

  const appLink = isAndroid
    ? 'intent://tap.az/#Intent;scheme=tapaz;package=org.tapaz.android;end'
    : 'https://apps.apple.com/us/app/tap-az/id1132083305';

  const handleCloseClick = () => {
    Cookies.set('appBannerShow', 'true', {
      expires: 7,
      domain: hostnameModified || '',
    });
    setIsAndroidBannerVisible(false);
  };

  useEffect(() => {
    setIsAndroidBannerVisible(!Cookies.get('appBannerShow'));
  }, []);

  const openIosApp = (e: React.MouseEvent) => {
    if (isIos) {
      e.preventDefault();
      window.location.href = 'tapaz://tap.az';
      setOnClickTime(true);
    }
    handleCloseClick();
  };

  useEffect(() => {
    if (onClickTime) {
      const appTimer = setTimeout(() => {
        window.location.href = appLink;
      }, timeOnAppStoreOpen);
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          return clearTimeout(appTimer);
        }
      });
    }
  }, [onClickTime]);

  return (
    isAndroidBannerVisible && (
      <NoSsr>
        <S.BannerContainer>
          <S.BannerClose onClick={handleCloseClick}>close</S.BannerClose>
          <S.Banner>
            <Image
              src="/images/app_banners/app-banner-logo.png"
              alt="Tap.Az"
              width={30}
              height={30}
            />
            <S.BannerText>
              {t('banners.app_banner_title')}
              <S.BannerDescription>
                {t('banners.app_banner_desc')}
              </S.BannerDescription>
            </S.BannerText>
          </S.Banner>
          <S.BannerLink href={appLink} onClick={openIosApp}>
            {t('banners.app_banner_open')}
          </S.BannerLink>
        </S.BannerContainer>
      </NoSsr>
    )
  );
};
